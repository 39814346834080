import React from 'react';
import './eight.css';
// import bg from "../assets/seven.png";
import bgtwo from '../assets/scene_7.png';
import mbBg from '../assets/mbBg-three.png';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import rect from '../assets/rect.png';
import ff from '../assets/ff.png';

function Eight() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  });

  console.log(entry);
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: {
      opacity: 0,
      // y: -100,
    },
  };
  return (
    <div ref={ref}>
      {isDesktopOrLaptop && (
        <motion.div
          animate={inView ? 'visible' : 'hidden'}
          variants={variants}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          style={{ height: '100vh' }}
          class="gs-slides-in gs-slides-show"
        >
          <div class="home-8" style={{ backgroundImage: `url(${bgtwo})` }}>
            {/* <div className="overlay"></div> */}
            <div className="press-kit-page">
              <div className="press-left">
                <h1>PRESS - KIT</h1>
                <div className="press-h4">
                  <img src={rect} alt="" />
                  <h4>MICTLAN AN ANCIENT MYSTICAL TALE</h4>
                </div>
                <p className="press-kit-text">
                  Mictlan is an action-adventure third-person game set in the
                  15th century
                </p>
                <p className="press-kit-text">
                  during the Spanish conquest of Mexico. Learn how to use
                  weapons,
                </p>
                <p className="press-kit-text">
                  conjure magic, pray the ancient Aztec Gods in order to survive
                  the
                </p>
                <p className="press-kit-text">
                  journey across Mictlan, the Valley of the Death.
                </p>
                <button>Download Press Kit</button>
              </div>
              <div className="press-right">
                <div
                  className="press-right-img"
                  style={{ backgroundImage: `url(${ff})` }}
                ></div>
                <div className="press-right-h4">
                  <h4>FACT SHEET</h4>
                  <img src={rect} alt="" />
                </div>
                <p className="press-kit-text-right">
                  Developer:
                  <br />
                  Meta Studios Creative Agency K.K 株式会社 <br />
                  Based in Tokyo, Japan{' '}
                </p>
                <p className="press-kit-text-right">
                  Release:
                  <br />• Winter, 2025
                </p>
                <p className="press-kit-text-right">
                  Platforms:
                  <br />• Playstation 5 | Xbox | Steam Deck | PC 
                </p>
                <p className="press-kit-text-right">
                  Website:
                  <br />
                  mictlanthegame.com
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
      {isTabletOrMobile && (
        <motion.div
          animate={inView ? 'visible' : 'hidden'}
          variants={variants}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          style={{ height: '100vh' }}
          class="gs-slides-in gs-slides-show"
        >
          <div
            class="h-screen background flex justify-center items-center"
            style={{ backgroundImage: `url(${bgtwo})` }}
          >
            {/* <div className="overlay"></div> */}
            <div className="press-kit-page">
              <div className="press-left">
                <h1>PRESS - KIT</h1>
                <div className="press-h4">
                  <img src={rect} alt="" />
                  <h4>MICTLAN AN ANCIENT MYSTICAL TALE</h4>
                </div>
                <p className="px-2 text-justify" id="mobFont">
                  Mictlan is an action-adventure third-person game set in the
                  15th century during the Spanish conquest of Mexico. Learn how
                  to use weapons, conjure magic, pray the ancient Aztec Gods in
                  order to survive the journey across Mictlan, the Valley of the
                  Death.
                </p>
                <button
                  className="w-8 h-2 flex justify-center items-center"
                  style={{ fontSize: '0.14rem' }}
                  id="mobFont"
                >
                  Download Press Kit
                </button>
              </div>
              <div className="press-right">
                <div
                  className="press-right-img"
                  style={{ backgroundImage: `url(${ff})` }}
                ></div>
                <div className="press-right-h4">
                  <h4>FACT SHEET</h4>
                  <img src={rect} alt="" />
                </div>
                <p className="press-kit-text-right">
                  Developer:
                  <br />
                  Meta Studios Creative Agency K.K 株式会社 <br />
                  Based in Tokyo, Japan{' '}
                </p>
                <p className="press-kit-text-right">
                  Release:
                  <br />• Winter, 2025
                </p>
                <p className="press-kit-text-right">
                  Platforms:
                  <br />• Playstation 5 | Xbox | Steam Deck | PC 
                </p>
                <p className="press-kit-text-right">
                  Website:
                  <br />
                  mictlanthegame.com
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default Eight;
